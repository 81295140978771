import React from "react";

import "components/configuration/configurationHeader/ConfigurationHeader.css";

interface ConfigurationHeaderProps {
  title: string;
  step: number;
  completed: boolean;
}

const ConfigurationHeader: React.FC<ConfigurationHeaderProps> = ({
  title,
  step,
  completed,
}) => {
  return (
    <div className="configuration-header">
      <h4>{title}</h4>
<span className={`badge fw-medium fs-2 ${completed ? "bg-success-subtle text-success" : "bg-primary-subtle text-primary"}`}>
  Step {step}
</span>{" "}
    </div>
  );
};

export default ConfigurationHeader;
