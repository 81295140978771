import React from "react";

import { ConfigurationStep } from "../body/Body";
import HelpSection from "./helpSection/HelpSection";
import "./Summary.css";
import SummaryList from "./summaryList/SummaryList";

interface ConfigurationProps {
  activeId: number
  configurationSteps: ConfigurationStep[];
}


const Summary: React.FC<ConfigurationProps> = ({activeId, configurationSteps}) => {
  return (
    <div className="summary border-left">
      <SummaryList items={configurationSteps} activeId={activeId} />
      <HelpSection title="Having Trouble?" content="If you need help, please contact our support team." />
    </div>
  );
};

export default Summary;
