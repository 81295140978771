import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ConfigurationStep } from "../../body/Body";

interface SummaryListProps {
  items: ConfigurationStep[];
  activeId: number;
}

const SummaryList: React.FC<SummaryListProps> = ({ items, activeId }) => {
  return (
    <ul className="list-unstyled">
      {items.map((item) => {
        const isActive = (item.id - 1) === activeId;
        const isSubtleCompleted = (item.id > activeId + 1) && item.completed;
        const isCompleted = item.completed && !isSubtleCompleted; 
        const isActiveAndCompleted = isActive && isCompleted;       

        const circleClass = isActiveAndCompleted ? 
        "circle-success rounded-circle-outline"
        : isActive
        ? "circle-primary rounded-circle-outline"
        : isSubtleCompleted
        ? "circle-success-subtle rounded-circle"
        : isCompleted
        ? "circle-success rounded-circle"
        : "circle-light rounded-circle-outline";

        return (
          <React.Fragment key={item.id}>
            <li>
              <span
                className={`circle ${circleClass} round-30`}
              >
                {item.completed ? <FontAwesomeIcon icon={faCheck} /> : item.id}
              </span>
              <div>
                <h6 className={(isCompleted && item.id <= activeId) || isActive ? "" : isCompleted && item.id > activeId ? "text-light" : "text-light"}>{item.title}</h6>
              </div>
            </li>
            {items.length - 1 !== items.indexOf(item) && (
              
              <li>
                <span
                  className="list-separator"
                  title={isCompleted && item.id <= activeId ? "success" : isCompleted && item.id > activeId ? "success-subtle": "light"}
                ></span>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </ul>
  );
};

export default SummaryList;
