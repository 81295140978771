import {
  faFireFlameSimple,
  faL,
  faTag,
  faThermometerFull,
  faThermometerThreeQuarters,
  faW
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "components/bottomBar/BottomBar.css";
import React from "react";

import InformationGroup from "components/bottomBar/InformationGroup";
import { AvailableSpaceItem } from "stores/ConfigurationStore";

interface NavigationProps {
  onContinuePress: (id: number) => void;
  onCancelPress: () => void;
  activeId: number;
}

interface RoomProps {
  roomName: string;
  temperatureSpecification: string;
  width: number;
  length: number;
  heatDemand: number;
  specificHeatingPower: number;
  availableSpaceItems: AvailableSpaceItem[];
}

interface BottomBarProps extends NavigationProps, RoomProps {}

const BottomBar: React.FC<BottomBarProps> = ({
  onContinuePress,
  onCancelPress,
  activeId,
  roomName,
  temperatureSpecification,
  width,
  length,
  heatDemand,
  specificHeatingPower,
  availableSpaceItems
}) => {
  const isSpaceConfiguration = activeId === 1;
  const isRoomConfiguration = activeId === 0;

  const isDisabled = () => {
    if (isRoomConfiguration) {
      return !isValidRoomConfiguration();
    }

    if (isSpaceConfiguration) {
      return !isValidSpaceConfiguration();
    }

    return false;
  }

  const isValidRoomConfiguration = () => {
    const validRoomName = roomName.trim() !== "";
    const validWidth = width > 0;
    const validTemperatureSpecification = temperatureSpecification !== "";
    const validLength = length > 0;
    const validSpecificHeatingPower = specificHeatingPower > 0;

    return validRoomName && validWidth && validTemperatureSpecification && validLength && validSpecificHeatingPower;
  }

  const isValidSpaceConfiguration = () => {
    return availableSpaceItems.every((space) => {
      const validLocation = space.location !== "Unset";
      const validWidth = space.width > 0;
      const validHeight = space.length > 0;

      return validLocation && validWidth && validHeight;
    });
  }


  return (
    <div className="bottom-bar">
      <div className="information">
        <InformationGroup
          icon={<FontAwesomeIcon icon={faTag} />}
          text={roomName}
          className="w-200"
        />
        <span className="information-group-divider"></span>
        <InformationGroup
          icon={<FontAwesomeIcon icon={faThermometerFull} />}
          text={`${temperatureSpecification}`}
        />
        <span className="information-group-divider"></span>
        <InformationGroup
          icon={<FontAwesomeIcon icon={faThermometerThreeQuarters} />}
          text={`${specificHeatingPower} W/m²`}
        />
                <span className="information-group-divider"></span>
        <InformationGroup
          icon={<FontAwesomeIcon icon={faW} />}
          text={`${width} m`}
        />
                <span className="information-group-divider"></span>
        <InformationGroup
          icon={<FontAwesomeIcon icon={faL} />}
          text={`${length} m`}
        />
        <span className="information-group-divider"></span>

        <InformationGroup
          icon={<FontAwesomeIcon icon={faFireFlameSimple} />}
          text={`${heatDemand} W`}
        />
      </div>
      <div className="buttons">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => onCancelPress()}
          disabled={activeId === 0}
        >
          Back
        </button>
        <button
          type="button"
          // TODO Add a parameter that will indicate the last step
          className={`btn ${activeId === 3 ? "btn-success" : "btn-primary"}`}
          onClick={() => onContinuePress(activeId + 1)}
          disabled={isDisabled()}
        >
          {activeId === 3 ? "Finish" : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default BottomBar;
