import { create } from "zustand";

enum RoomType {
  Kitchen = 1.5,
  Basement = 1.2,
  Bathroom = 1.8,
  Bedroom = 1.0,
  Office = 1.3,
}

export enum Location {
  Wall = "Wall",
  Ceiling = "Ceiling",
  Unset = "Unset",
}

export interface AvailableSpaceItem {
  location: Location;
  width: number;
  length: number;
}

interface ConfigurationState {
  roomName: string;
  temperatureSpecification: string;
  width: number;
  length: number;
  specificHeatingPower: number;
  heatDemand: number;
  availableSpace: AvailableSpaceItem[];
  setRoomName: (name: string) => void;
  setWidth: (width: number) => void;
  setLength: (length: number) => void;
  setSpecificHeatingPower: (level: number) => void;
  setTemperatureSpecification: (temperatureSpecification: string) => void;
  onSpaceAdd: (space?: AvailableSpaceItem) => void;
  onSpaceUpdate: (index: number, space: AvailableSpaceItem) => void;
  onSpaceRemove: (index: number) => void;
}

const useConfigurationStore = create<ConfigurationState>((set, get) => ({
  roomName: "",
  temperatureSpecification: "",
  width: 0,
  length: 0,
  specificHeatingPower: 120,
  heatDemand: 0,
  availableSpace: [
    {
      location: Location.Unset,
      width: 0,
      length: 0,
    },
  ],

  setRoomName: (name: string) =>
    set(() => {
      let { specificHeatingPower } = get();
      const roomType = RoomType[name as keyof typeof RoomType];

      specificHeatingPower = 120 * roomType;

      return { roomName: name, specificHeatingPower };
    }),

  setWidth: (width: number) =>
    set(() => {
      const { length, specificHeatingPower } = get();

      const heatDemand = round(length * width * specificHeatingPower, 3);

      width = round(width, 3);

      return { width, heatDemand };
    }),

  setLength: (length: number) =>
    set(() => {
      const { width, specificHeatingPower } = get();

      const heatDemand = round(length * width * specificHeatingPower, 3);

      length = round(length, 3);

      return { length, heatDemand };
    }),

  setSpecificHeatingPower: (specificHeatingPower: number) =>
    set(() => {
      const { width, length } = get();
      const heatDemand = round(width * length * specificHeatingPower, 3);

      specificHeatingPower = round(specificHeatingPower, 3);

      return { specificHeatingPower, heatDemand };
    }),

  setTemperatureSpecification: (temperatureSpecification: string) =>
    set(() => ({ temperatureSpecification })),

  onSpaceAdd: (space) =>
    set((state) => {
      const newSpace =
        space || {
          location: Location.Unset,
          width: 0,
          length: 0,
        };
      return { availableSpace: [...state.availableSpace, newSpace] };
    }),

  onSpaceUpdate: (index: number, space: AvailableSpaceItem) =>
    set((state) => {
      const availableSpace = [...state.availableSpace];
      availableSpace[index] = space;
      return { availableSpace };
    }),

    onSpaceRemove: (index: number) =>
    set((state) => {
      const availableSpace = [...state.availableSpace];
      availableSpace.splice(index, 1);
      return { availableSpace };
    }),
}));

const round = (value: number, digits: number): number => {
  const factor = Math.pow(10, digits);
  return Math.round(value * factor) / factor;
};

export default useConfigurationStore;
