import React from "react";

import "./HelpSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLifeRing } from "@fortawesome/free-solid-svg-icons";

interface HelpSectionProps {
  title: string;
  content: string;
}

const HelpSection: React.FC<HelpSectionProps> = ({ title, content }) => {
  return (
    <div className="help-section">
      <FontAwesomeIcon icon={faLifeRing} />
      <h5>{title}</h5>
      <p>{content}</p>
      <button className="btn btn-outline-dark">Contact Us</button>
    </div>
  );
};

export default HelpSection;
