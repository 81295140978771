import {
  faArrowsLeftRightToLine,
  faArrowsUpToLine,
  faL,
  faPlus,
  faQuestion,
  faTrashCan,
  faW,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "components/configuration/spaceConfiguration/SpaceConfiguration.css";
import React from "react";
import { AvailableSpaceItem, Location } from "stores/ConfigurationStore";
import useValidationStore from "stores/ValidationStore";
import { validateLastSpaceItem } from "utils/validation";

interface SpaceConfigurationProps {
  availableSpaceItems: AvailableSpaceItem[];
  onSpaceAdd: (space: AvailableSpaceItem) => void;
  onSpaceUpdate: (id: number, updatedSpace: AvailableSpaceItem) => void;
  onSpaceRemove: (id: number) => void;
}

const mapFieldToErrorKey = (field: keyof AvailableSpaceItem): string => {
  switch (field) {
    case "width":
      return "spaceWidth";
    case "length":
      return "spaceHeight";
    case "location":
      return "spaceLocation";
    default:
      return "";
  }
};

const SpaceConfiguration: React.FC<SpaceConfigurationProps> = ({
  availableSpaceItems,
  onSpaceAdd,
  onSpaceUpdate,
  onSpaceRemove,
}) => {
  const { errors, setError, clearError } = useValidationStore();
  const locations = Object.values(Location);

  const handleInputChange = (
    index: number,
    field: keyof AvailableSpaceItem,
    value: string | number
  ) => {
    // clear the errors of the specific field
    clearError(mapFieldToErrorKey(field));

    const updatedRow = {
      ...availableSpaceItems[index],
      [field]: value,
    };
    onSpaceUpdate(index, updatedRow);
  };

  const handleOnAdd = () => {
    const lastItem = availableSpaceItems[availableSpaceItems.length - 1];

    // Validate the last item before adding a new one
    const isValid = validateLastSpaceItem(lastItem, setError, clearError);

    if (!isValid) return;

    // Add a new space item
    onSpaceAdd({
      location: Location.Unset,
      width: 0,
      length: 0,
    });
  };

  const handleOnRemove = (index: number) => {
    if (index === 0) return;

    clearErrors();
    onSpaceRemove(index);
  };

  const clearErrors = () => {
    Object.keys(errors).forEach((key) => {
      setError(key, "");
    });
  };

  return (
    <div className="configuration-item">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Location</th>
              <th>Width (m)</th>
              <th>Length (m)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {availableSpaceItems.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <div className="relative">
                    <select
                      id="spaceLocation"
                      className={
                        errors.spaceLocation &&
                        index === availableSpaceItems.length - 1
                          ? "error"
                          : ""
                      }
                      value={row.location}
                      onChange={(e) =>
                        handleInputChange(index, "location", e.target.value)
                      }
                    >
                      <option value={Location.Unset} disabled>
                        Select location
                      </option>
                      {locations.map(
                        (location) =>
                          location !== Location.Unset && (
                            <option key={location} value={location}>
                              {location}
                            </option>
                          )
                      )}
                    </select>
                    <span className="icon">
                      <FontAwesomeIcon
                        icon={
                          row.location === "Ceiling"
                            ? faArrowsUpToLine
                            : row.location === "Wall"
                            ? faArrowsLeftRightToLine
                            : faQuestion
                        }
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <div className="relative">
                    <input
                      id="spaceWidth"
                      type="number"
                      className={
                        errors.spaceWidth &&
                        index === availableSpaceItems.length - 1
                          ? "error"
                          : ""
                      }
                      value={row.width === 0 ? "" : row.width}
                      placeholder="0"
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "width",
                          Number(e.target.value)
                        )
                      }
                    />
                    <span className="icon">
                      <FontAwesomeIcon icon={faW} />
                    </span>
                    <span className="unit">m</span>
                  </div>
                </td>
                <td>
                  <div className="relative">
                    <input
                      id="spaceHeight"
                      type="number"
                      className={
                        errors.spaceHeight &&
                        index === availableSpaceItems.length - 1
                          ? "error"
                          : ""
                      }
                      value={row.length === 0 ? "" : row.length}
                      placeholder="0"
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "length",
                          Number(e.target.value)
                        )
                      }
                    />
                    <span className="icon">
                      <FontAwesomeIcon icon={faL} />
                    </span>
                    <span className="unit">m</span>
                  </div>
                </td>
                <td className={`${index === 0 ? "remove disabled" : "remove"}`}>
                  <div onClick={() => handleOnRemove(index)}>
                    <FontAwesomeIcon icon={faTrashCan} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="add-row" onClick={() => handleOnAdd()}>
        <span className="line"></span>
        <div className="add-row-icon">
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <span className="line"></span>
      </div>
    </div>
  );
};

export default SpaceConfiguration;
