import React from "react";
import CalculationHeader from "../CalculationHeader/CalculationHeader";

interface CalculationItemProps {
  step: number;
  radiators: number;
  heating: number;
}

const CalculationItem: React.FC<CalculationItemProps> = ({
  step,
  radiators,
  heating,
}) => {
  return (
    <div className="calculation-item">
      <CalculationHeader step={step} label="Suggested Combination" radiators={radiators} heating={heating} />
      
    </div>
  );
};

export default CalculationItem;
