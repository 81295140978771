import React from "react";

interface ValidatedDropdownFieldProps {
  id: string;
  label: string;
  options: { value: string; label: string }[];
  description: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  icon: React.ReactNode; // FontAwesome icon
  hasBorderBottom: boolean;
  decreaseBottomPadding?: boolean;
  decreaseTopPadding?: boolean;
  noLabel?: boolean;
}

const ValidatedDropdownField: React.FC<ValidatedDropdownFieldProps> = ({
  id,
  label,
  options,
  description,
  value,
  onChange,
  error,
  hasBorderBottom,
  decreaseBottomPadding,
  decreaseTopPadding,
  noLabel,
  icon,
}) => {
  let formGroupClasses = hasBorderBottom
    ? "form-group border-bottom"
    : "form-group";
  formGroupClasses = decreaseBottomPadding
    ? `${formGroupClasses} decrease-bottom-padding`
    : formGroupClasses;
  formGroupClasses = decreaseTopPadding
    ? `${formGroupClasses} decrease-top-padding`
    : formGroupClasses;

  return (
    <div className={formGroupClasses}>
      {!noLabel && (
        <div className="form-group-item">
          <label className="form-group-label" htmlFor={id}>
            {label}
          </label>
          <p>{description}</p>
        </div>
      )}
      <div className="form-group-item">
        <div className="relative">
          <select
            id={id}
            value={value}
            className={error ? "error" : ""}
            onChange={(e) => onChange(e.target.value)}
          >
            <option value="">Select an option...</option>
            {options.map((option) => (
              <option key={option.value} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>
          {icon && (
            <span className={error ? "icon error" : "icon"}>{icon}</span>
          )}
        </div>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default ValidatedDropdownField;
