import React from "react";

import { ConfigurationStep } from "components/body/Body";
import BottomBar from "components/bottomBar/BottomBar";
import "components/configuration/Configuration.css";
import ConfigurationHeader from "components/configuration/configurationHeader/ConfigurationHeader";
import RoomConfiguration from "components/configuration/roomConfiguration/RoomConfiguration";
import SpaceConfiguration from "components/configuration/spaceConfiguration/SpaceConfiguration";
import useConfigurationStore from "stores/ConfigurationStore";
import CalculatorConfiguration from "./calculatorConfiguration/CalculatorConfiguration";

interface ConfigurationProps {
  activeId: number;
  configurationSteps: ConfigurationStep[];
  onContinuePress: (id: number) => void;
  onCancelPress: () => void;
}

const Configuration: React.FC<ConfigurationProps> = ({
  configurationSteps,
  activeId,
  onContinuePress,
  onCancelPress,
}) => {
  const {
    roomName,
    temperatureSpecification,
    width,
    length,
    heatDemand,
    specificHeatingPower,
    availableSpace,
    setRoomName,
    setWidth,
    setLength,
    setSpecificHeatingPower,
    setTemperatureSpecification,
    onSpaceAdd,
    onSpaceUpdate,
    onSpaceRemove,
  } = useConfigurationStore();

  return (
    <div className="configuration">
      <div className="content">
        <ConfigurationHeader
          title={configurationSteps[activeId].title}
          step={configurationSteps[activeId].id}
          completed={configurationSteps[activeId].completed}
        />

        {activeId === 0 && (
          <RoomConfiguration
            roomName={roomName}
            onNameChange={setRoomName}
            width={width}
            onWidthChange={setWidth}
            temperatureSpecification={temperatureSpecification}
            onTemperatureSpecificationChange={setTemperatureSpecification}
            length={length}
            onLengthChange={setLength}
            specificHeatingPower={specificHeatingPower}
            onEnergyLevelChange={setSpecificHeatingPower}
          />
        )}

        {activeId === 1 && (
          <SpaceConfiguration
          availableSpaceItems={availableSpace}
          onSpaceAdd={onSpaceAdd}
          onSpaceUpdate={onSpaceUpdate}
          onSpaceRemove={onSpaceRemove}
          />
        )}

        {activeId === 2 && (
          <CalculatorConfiguration />
        )}
      </div>

      <BottomBar
        roomName={roomName}
        temperatureSpecification={temperatureSpecification}
        width={width}
        length={length}
        heatDemand={heatDemand}
        specificHeatingPower={specificHeatingPower}
        onContinuePress={onContinuePress}
        onCancelPress={onCancelPress}
        activeId={activeId}
        availableSpaceItems={availableSpace}
      />
    </div>
  );
};

export default Configuration;