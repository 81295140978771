import CalculationItem from "components/configuration/calculatorConfiguration/calculationItem/CalculationItem";
import "components/configuration/calculatorConfiguration/CalculatorConfiguration.css";
import React from "react";

const CalculatorConfiguration: React.FC = () => {
  const calculationData = [
    { step: 1, radiators: 3, heating: 12300 },
    { step: 2, radiators: 3, heating: 12300 },
    { step: 3, radiators: 5, heating: 12300 },
    { step: 4, radiators: 3, heating: 12300 },
  ];

  return (
    <div className="configuration-item">
      {calculationData.map((item) => (
        <CalculationItem
          key={item.step}
          step={item.step}
          radiators={item.radiators}
          heating={item.heating}
        />
      ))}
    </div>
  );
};

export default CalculatorConfiguration;
