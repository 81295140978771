// store/validationStore.ts
import { create } from "zustand";

interface ValidationState {
  errors: Record<string, string>; // A map of field IDs to error messages
  setError: (field: string, message: string) => void;
  clearError: (field: string) => void;
}

const useValidationStore = create<ValidationState>((set) => ({
  errors: {},

  setError: (field, message) =>
    set((state) => ({
      errors: { ...state.errors, [field]: message },
    })),

  clearError: (field) =>
    set((state) => {
      const newErrors = { ...state.errors };
      delete newErrors[field];
      return { errors: newErrors };
    }),
}));

export default useValidationStore;
