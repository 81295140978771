import { faFireFlameSimple, faHashtag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface CalculationHeaderProps {
  step: number;
  label: string;
  radiators: number;
  heating: number;
}

const CalculationHeader: React.FC<CalculationHeaderProps> = ({ step, label, radiators, heating }) => {
  return (
    <div className="calculation-header">
      <div className="calculation-header__label">
        <span className="step">{step}</span>
        {label}
      </div>
      <div className="calculation-item__info">
        <div className="info-element calculation-icon">
          <FontAwesomeIcon icon={faHashtag} />
          {radiators}
        </div>
        <div className="info-element calculation-icon">
          <FontAwesomeIcon icon={faFireFlameSimple} /> {heating} W
        </div>
      </div>
    </div>
  );
};

export default CalculationHeader;
