import "components/header/Header.css";
import React from "react";

const Header: React.FC = () => {
  return (
    <header className="header border-bottom"></header>
  );
};

export default Header;
