import {
  faL,
  faTag,
  faThermometerFull,
  faThermometerThreeQuarters,
  faW,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import ValidatedDropdownField from "components/formElements/ValidatedDropdownField";
import ValidatedNumberInputField from "components/formElements/ValidatedNumberInputField";
import useValidationStore from "stores/ValidationStore";
import { validateNumberInput } from "utils/validation";

interface RoomConfigurationProps {
  roomName: string;
  onNameChange: (name: string) => void;

  width: number;
  onWidthChange: (width: number) => void;

  temperatureSpecification: string;
  onTemperatureSpecificationChange: (temperatureSpecification: string) => void;

  length: number;
  onLengthChange: (length: number) => void;

  specificHeatingPower: number;
  onEnergyLevelChange: (specificHeatingPower: number) => void;
}

const RoomConfiguration: React.FC<RoomConfigurationProps> = (props) => {
  const { errors, setError, clearError } = useValidationStore();

  return (
    <form className="configuration-item">
      {/* Room Name */}
      <ValidatedDropdownField
        id="roomName"
        label="Room Name"
        options={[
          { value: "kitchen", label: "Kitchen" },
          { value: "basement", label: "Basement" },
          { value: "bathroom", label: "Bathroom" },
          { value: "bedroom", label: "Bedroom" },
          { value: "office", label: "Office" },
        ]}
        description="The Room Name identifies the item as it will appear on the quote."
        icon={<FontAwesomeIcon className="text-primary" icon={faTag} />}
        hasBorderBottom={true}
        value={props.roomName}
        onChange={(value) => props.onNameChange(value)}
        error={errors.roomName}
      />

      {/* Specific Heating Power */}
      <ValidatedNumberInputField
        id="specificHeatingPower"
        label="Specific Heating Power"
        hasBorderBottom={true}
        description="The Energy Level is the amount of energy that the room consumes."
        value={props.specificHeatingPower}
        onChange={(value) =>
          validateNumberInput(
            value,
            "specificHeatingPower",
            props.onEnergyLevelChange,
            setError,
            clearError
          )
        }
        error={errors.specificHeatingPower}
        icon={
          <FontAwesomeIcon
            className="text-primary icon-smaller"
            icon={faThermometerThreeQuarters}
          />
        }
        unit="W/m²"
      />

      {/* Temperature Specification */}
      <ValidatedDropdownField
        id="temperatureSpecification"
        label="Temperature Specification"
        options={[
          { value: "55/45-20", label: "55/45-20" },
          { value: "45/35-20", label: "45/35-20" },
          { value: "35/30-20", label: "35/30-20" },
          { value: "70/40-20", label: "70/40-20" },
        ]}
        description="The Temperature Specification is the in/out temperature, and the room temperature."
        icon={
          <FontAwesomeIcon
            className="text-primary icon-smaller"
            icon={faThermometerFull}
          />
        }
        hasBorderBottom={true}
        value={props.temperatureSpecification}
        onChange={(value) => props.onTemperatureSpecificationChange(value)}
        error={errors.temperatureSpecification}
      />

      {/* Width */}
      <ValidatedNumberInputField
        id="width"
        label="Room Width"
        hasBorderBottom={false}
        decreaseBottomPadding={true}
        description="The width of a room is the distance between the two longest sides."
        value={props.width}
        onChange={(value) =>
          validateNumberInput(
            value,
            "width",
            props.onWidthChange,
            setError,
            clearError
          )
        }
        error={errors.width}
        icon={
          <FontAwesomeIcon className="text-primary icon-smaller" icon={faW} />
        }
        unit="m"
      />

      {/* Length */}
      <ValidatedNumberInputField
        id="length"
        label="Room Length"
        hasBorderBottom={false}
        decreaseTopPadding={true}
        description="The length of a room is the distance between the two shorter sides."
        value={props.length}
        onChange={(value) =>
          validateNumberInput(
            value,
            "length",
            props.onLengthChange,
            setError,
            clearError
          )
        }
        error={errors.length}
        icon={
          <FontAwesomeIcon className="text-primary icon-smaller" icon={faL} />
        }
        unit="m"
      />
    </form>
  );
};

export default RoomConfiguration;
