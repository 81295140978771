import React from "react";

interface InformationGroupProps {
  icon: React.ReactNode; // The FontAwesome icon
  text: string | number; // The text or value to display
  className?: string; // Optional additional CSS classes
}

const InformationGroup: React.FC<InformationGroupProps> = ({
  icon,
  text,
  className = "",
}) => {
  return (
    <div className={`information-group ${className}`}>
      {icon && <span className="text-primary">{icon}</span>}
      <p className="text-muted">{text || "-"}</p>
    </div>
  );
};

export default InformationGroup;
