import React from "react";

interface ValidatedInputFieldProps {
  id: string; // ID for the input field
  label: string; // Label text
  description: string; // Field description
  value: number; // Current value
  onChange: (value: number) => void; // Change handler
  error?: string; // Error message
  icon: React.ReactNode; // FontAwesome icon
  unit?: string; // Optional unit text,
  hasBorderBottom: boolean;
  decreaseBottomPadding?: boolean;
  decreaseTopPadding?: boolean;
  hasDisabledLook?: boolean;
  hasLabel?: boolean;
  className?: string;
}

const ValidatedInputField: React.FC<ValidatedInputFieldProps> = ({
  id,
  label,
  description,
  value,
  onChange,
  error,
  icon,
  unit,
  className = "",
  hasBorderBottom,
  decreaseBottomPadding,
  decreaseTopPadding,
  hasDisabledLook,
  hasLabel = true,
}) => {
  let formGroupClasses = hasBorderBottom ? "form-group border-bottom" : "form-group";
  formGroupClasses = decreaseBottomPadding ? `${formGroupClasses} decrease-bottom-padding` : formGroupClasses;
  formGroupClasses = decreaseTopPadding ? `${formGroupClasses} decrease-top-padding` : formGroupClasses;
  formGroupClasses = hasDisabledLook ? `${formGroupClasses} disabled-look` : formGroupClasses;

  formGroupClasses = className ? className : formGroupClasses;
  
  return (
    <div className={formGroupClasses}>
      {
        hasLabel && (
          <div className="form-group-item">
            <label className="form-group-label" htmlFor={id}>
              {label}
            </label>
            <p>{description}</p>
          </div>
        )
      }
      
      <div className="form-group-item">
        <div className="relative">
          <input
            id={id}
            type="number"
            className={error ? "error" : ""}
            placeholder="0"
            value={value === 0 ? "" : value}
            autoComplete="off"
            onChange={(e) => onChange(Number(e.target.value))}
            disabled={hasDisabledLook}
          />
          <span className={error ? "icon error" : "icon"}>{icon}</span>
          {unit && <span className="unit text-primary">{unit}</span>}
        </div>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default ValidatedInputField;
