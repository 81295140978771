import React from "react";

import "components/body/Body.css";

import Configuration from "components/configuration/Configuration";
import Summary from "components/summary/Summary";

export interface ConfigurationStep {
  title: string;
  id: number;
  completed: boolean;
}

const configurationSteps: ConfigurationStep[] = [
  { title: "Set up your room", id: 1, completed: false },
  { title: "Define available space", id: 2, completed: false },
  { title: "View & Select Matching Radiators", id: 3, completed: false },
  { title: "Overview & Save Room", id: 4, completed: false },
];

const Body: React.FC = () => {
  const [activeStepId, setActiveStepId] = React.useState<number>(0);

  const handleStepChange = (id: number) => {
    if (id < configurationSteps.length) {
      setActiveStepId(id);
    }

    // set every item before the active one to completed
    configurationSteps.forEach((step, index) => {
      if (index < id) {
        step.completed = true;
      }
    });
  };

  const handleCancelPress = () => {
    if (activeStepId <= 0) return;

    setActiveStepId(activeStepId - 1);
  };

  return (
    <div className="body">
      <Configuration
        activeId={activeStepId}
        configurationSteps={configurationSteps}
        onContinuePress={handleStepChange}
        onCancelPress={handleCancelPress}
      />
      <Summary
        configurationSteps={configurationSteps}
        activeId={activeStepId}
      />
    </div>
  );
};

export default Body;
