import { AvailableSpaceItem, Location } from "../stores/ConfigurationStore";

export const validateNumberInput = (
  value: number,
  field: string,
  onChange: (value: number) => void,
  setError: (field: string, message: string) => void,
  clearError: (field: string) => void
): void => {
  if (value < 0) {
    setError(field, "Value must be a positive number");
  } else {
    clearError(field);
    onChange(value);
  }
};

// ----------------------------

export const validateLastSpaceItem = (
  lastItem: AvailableSpaceItem,
  setError: (field: string, message: string) => void,
  clearError: (field: string) => void
): boolean => {
  const validations = [
    {
      field: "spaceHeight",
      value: lastItem.length,
      errorMessage: "Height is required",
      invalidValue: 0, // Rule: Required (cannot be zero)
    },
    {
      field: "spaceHeight",
      value: lastItem.length,
      errorMessage: "Height cannot be negative",
      isInvalid: (value: number) => value < 0, // Rule: Cannot be negative
    },
    {
      field: "spaceWidth",
      value: lastItem.width,
      errorMessage: "Width is required",
      invalidValue: 0, // Rule: Required (cannot be zero)
    },
    {
      field: "spaceWidth",
      value: lastItem.width,
      errorMessage: "Width cannot be negative",
      isInvalid: (value: number) => value < 0, // Rule: Cannot be negative
    },
    {
      field: "spaceLocation",
      value: lastItem.location,
      errorMessage: "Location is required",
      invalidValue: Location.Unset, // Rule: Cannot be unset
    },
  ];

  return runValidations(validations, setError, clearError);
};

const runValidations = (
  validations: {
    field: string;
    value: any;
    errorMessage: string;
    invalidValue?: any; // Default invalid value (e.g., 0 or Location.Unset)
    isInvalid?: (value: any) => boolean; // Custom rule for advanced validation
  }[],
  setError: (field: string, message: string) => void,
  clearError: (field: string) => void
): boolean => {
  let hasErrors = false;
  const errorMap: Record<string, string[]> = {};

  validations.forEach(
    ({ field, value, errorMessage, invalidValue, isInvalid }) => {
      const isFieldInvalid =
        (invalidValue !== undefined && value === invalidValue) || // Check invalidValue
        (isInvalid && isInvalid(value)); // Check custom invalid rule

      if (isFieldInvalid) {
        if (!errorMap[field]) {
          errorMap[field] = [];
        }
        errorMap[field].push(errorMessage);
        hasErrors = true;
      }
    }
  );

  // Apply errors or clear them for each field
  Object.keys(errorMap).forEach((field) => {
    setError(field, errorMap[field].join(". "));
  });

  // Clear errors for fields that passed validation
  validations
    .map((v) => v.field)
    .filter((field) => !errorMap[field])
    .forEach((field) => clearError(field));

  return !hasErrors;
};
